<template>
	<el-dialog :title="!dataForm.testId && dataForm.testId===0 ? '新增检验项目' : '修改检验项目'"
               :close-on-click-modal="false"
               :visible.sync="visible"
		width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
<!--				<el-col :span="12">-->
<!--					<el-form-item label="标的物">-->
<!--						<el-select class="selItemInput" v-model="dataForm.subjectNo" placeholder="请选择">-->
<!--							<el-option-->
<!--							  v-for="item in subjectList"-->
<!--							  :key="item.displayValue"-->
<!--							  :label="item.displayName"-->
<!--							  :value="item.displayValue">-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="12">
					<el-form-item label="专业">
						<el-select class="selItemInput" v-model="dataForm.major" placeholder="请选择">
							<el-option
									v-for="item in majorList"
									:key="item.displayValue"
									:label="item.displayName"
									:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
<!--				<el-col :span="12">-->
<!--					<el-form-item label="范畴">-->
<!--						<el-select class="selItemInput" v-model="dataForm.category" placeholder="请选择">-->
<!--							<el-option-->
<!--									v-for="item in categoryList"-->
<!--									:key="item.displayValue"-->
<!--									:label="item.displayName"-->
<!--									:value="item.displayValue">-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="12">
					<el-form-item label="检验项目" prop="probContext">
						<el-input class="selItemInput" v-model="dataForm.probContext" placeholder="请输入" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="参考文件/标准" prop="testReferences">
						<el-input class="selItemInput" v-model="dataForm.testReferences" placeholder="请输入" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="检验形式">
						<el-select class="selItemInput" v-model="dataForm.testType" placeholder="请选择">
							<el-option
									v-for="item in testTypeList"
									:key="item.displayValue"
									:label="item.displayName"
									:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "testItem-add-or-update",
		data() {
			return {
				value:"",
				majorList:[],
				visible: false,
				inline: false,
				form:{},
				subjectList:[],
				testTypeList:[],
				categoryList:[],
				dataForm: {
					workNo:$common.getItem("workNo"),
					testId:"",
					subjectNo:$common.getItem("subjectNo"),
					major:"",
					category:"",
					probContext:"",
					testReferences:"",
					testType:""
				},
				dataRule: {

                },
			}
		},
		methods: {
			init(id,category) {
				// this.getSubjectDropDown();
				this.getTestItemTestType();
				// this.getCategoryList();
                this.dataForm = {
                    workNo:$common.getItem("workNo"),
                    testId:"",
                    subjectNo:$common.getItem("subjectNo"),
                    major:"",
                    category:"",
                    probContext:"",
                    testReferences:"",
                    testType:""
                }
                this.dataForm.testId = id || 0;
                this.dataForm.category = category;
				this.getMajorList();
                if(id){
                    this.getDetail();
                }
				this.$nextTick(() => {
                    this.visible = true;
				})
			},
			getTestItemTestType(){
				this.$http({
				  url:  this.$store.state.httpUrl + "/common/getTestItemTestType",
				  method: "get",
				  params: {},
				}).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					this.testTypeList = data.body;
				  }
				});
			},
			// getCategoryList(){
			// 	this.$http({
			// 	  url:  this.$store.state.httpUrl + "/common/getCategoryList",
			// 	  method: "get",
			// 	  params: {},
			// 	}).then(({ data }) => {
			// 	  if (data && data.resultCode === 200) {
			// 		this.categoryList = data.body;
			// 	  }
			// 	});
			// },
			// getSubjectDropDown(){
			// 	this.$http({
			// 	  url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
			// 	  method: "get",
			// 	  params: {},
			// 	}).then(({ data }) => {
			// 	  if (data && data.resultCode === 200) {
			// 		this.subjectList = data.body;
			// 	  }
			// 	});
			// },
			getMajorList(){
				this.$http({
				  url:  this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				  method: "get",
				  params: {},
				}).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					this.majorList = data.body;
				  }
				});
			},
            getDetail(){
                this.$http({
                    url: this.$store.state.httpUrl + "/business/testitem/info/"+this.dataForm.testId,
                    method: "get",
                    params: {},
                }).then(({ data }) => {
                    if (data && data.resultCode === 200) {
                        this.dataForm = data.body;
                    }
                });
            },

            //提交数据
            dataFormSubmit() {
                this.$refs["dataForm"].validate(valid => {
                    if (valid) {
                        let _url = "/business/testitem/save";
                        if(this.dataForm.testId != 0){
                            _url = "/business/testitem/update";
                        }
                        this.$http({
                            url: this.$store.state.httpUrl + _url,
                            method: "post",
                            data: this.$http.adornData(this.dataForm)
                        }).then(({ data }) => {
                            if (data && data.resultCode === 200) {
                                this.$message({
                                    message: "操作成功",
                                    type: "success",
                                    duration: 1500,
                                    onClose: () => {
                                        this.visible = false;
										this.$parent.getDataList();
                                    }
                                });
                            } else {
                                this.$message.error(data.msg);
                            }
                        });
                    }
                });
            }
		}
	}
</script>
<style scoped>

</style>
