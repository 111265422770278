<template>
	<el-dialog
		title="报验项目导入"
		:close-on-click-modal="false"
		:visible.sync="visible"
		width="800px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
<!--				<el-col :span="24">-->
<!--					<el-form-item label="标的物">-->
<!--						<el-select class="selItemInput" v-model="dataForm.subjectNo" placeholder="请选择">-->
<!--							<el-option-->
<!--									v-for="item in subjectList"-->
<!--									:key="item.displayValue"-->
<!--									:label="item.displayName"-->
<!--									:value="item.displayValue">-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="24">
					<el-form-item label="上传文件">
						<el-upload
						  class="addFileBox"
						  ref="uploadFile"
						  :action="action"
						  :file-list="fileList"
						  accept=".xls,.xlsx"
						  :on-change="uploadFile"
						  :auto-upload="false"
						  :show-file-list="true">
						  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder-add" style="padding: 9px 0 !important;">添加文件</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
			  <el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			  </el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import $common from "@/utils/common.js"
	import xlsx from 'xlsx'
	export default {
	  name: "testItem-upload",
	  data() {
	    return {
			id: "",
			visible: true,
			form:{},
			action:'',
			fileList:[],
			subjectList:[],
			excelRowVale:[],
			dataForm:{
				workNo: $common.getItem("workNo"),
				subjectNo:$common.getItem("subjectNo"),
			},
			dataRule: {

			},
	    };
	  },
	  components: {
	  },
	  activated() {

	  },
	  methods: {
		init() {
			this.excelRowVale = [];
			this.$refs.uploadFile.value = '';
			this.fileList = [];
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo:$common.getItem("subjectNo"),
			}
			// this.getSubjectDropDown();
		    this.$nextTick(() => {
		    	this.visible = true;
		    })
		},
		//标的物
		// getSubjectDropDown(){
		//   this.$http({
		// 	  url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
		// 	  method: "get",
		// 	  params: {},
		//   }).then(({ data }) => {
		// 	  if (data && data.resultCode === 200) {
		// 		  this.subjectList = data.body;
		// 		  this.subjectNo = this.subjectList[0].displayValue;
		// 	  }
		//   });
		// },
		uploadFile(file,fileList){
			let files = {0:file.raw}
			this.readExcel(files)
		},
		readExcel(files){
			let that = this;
			if (files.length <= 0){
				return false;
			} else if(!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())){
				this.$message.error('上传格式不正确，请上传xls或者xlsx格式');
				return false;
			}
			const fileReader = new FileReader();
			fileReader.onload = (ev) => {
				try {
					const data = ev.target.result;
					const workbook = xlsx.read(data, {
						type: 'binary'
					});
					const wsname = workbook.SheetNames[0];//取第一张表
					const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]);//生成json表格内容
					let rowData = [...ws]
					that.excelRowVale = [];
					rowData.map(v => {
						let obj = {};
						obj.workNo = that.dataForm.workNo;
						obj.major = v.专业;
						obj.category = v.范畴;
						obj.subjectNo = that.dataForm.subjectNo;
						obj.testType = v.检验形式;
						obj.probContext = v.检验项目;
						obj.testReferences = v.参考文件和标准;
						that.excelRowVale.push(obj);
					});
					//重写数据
					// this.$refs.upload.value = '';
				} catch (e) {
					return false;
				}
			};
			fileReader.readAsBinaryString(files[0]);
		},
		dataFormSubmit() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/testitem/saveSubjectNo",
				method: "post",
				data: this.$http.adornData({
					records: this.excelRowVale
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.visible = false;
							this.$emit("refreshDataList");
						}
					});
				} else {
					this.$message.error(data.msg);
				}
			});
		}
	  }
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
</style>
