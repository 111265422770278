<template>
	<!-- 报检项目 -->
  <div class="mod-config">
	  <el-card body-style="padding-bottom: 0;">
		<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
			<el-row>
				<el-col :span="20">
					<el-form-item label="专业">
						<el-select class="selItemInput" v-model="major" placeholder="请选择">
							<el-option
							  v-for="item in majorList"
							  :key="item.displayValue"
							  :label="item.displayName"
							  :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="4"  class="selBtnGroup">
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="resetFormData()">重置</el-button>
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="selGetDataList()" v-if="showSearch">检索</el-button>
				</el-col>
			</el-row>
		</el-form>
	  </el-card>
	  <el-row style="margin-top: 20px;">
		  <el-col :span="pageSpan">
			  <el-button v-preventReClick class="defaultPlainBtn" plain @click="toggleAllSelection()" v-if="showSelect">
				  <el-checkbox v-model="selAllFlg">全选</el-checkbox>
			  </el-button>
			  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-circle-plus-outline" @click="addOrUpdateHandle()" v-if="showAdd">新增</el-button>
			  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-download" @click="downloadTemplate()" v-if="showDownload">模板导出</el-button>
			  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-upload2" @click="uploadTestItem()" v-if="showUpload">导入</el-button>
			  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-delete" @click="deletePHandle()" v-if="showDelete">删除</el-button>
		  </el-col>
		  <el-col :span="pageSpan" style="text-align: right;">
			  <el-pagination
			    background
				@size-change="sizeChangeHandle"
				@current-change="currentChangeHandle"
			    layout="prev, pager, next"
				:current-page="pageIndex"
				:page-size="pageSize"
				:total="totalPage">
			  </el-pagination>
		  </el-col>
	  </el-row>
	  <div :style="siteContentViewHeight()">
		  <el-card
				  style="margin-top: 20px; height: 100%;"
				  body-style="height: 100%; padding: 0;">
			  <el-row style="height: 100%;">
<!--				  <el-col :span="2" style="background-color: #e9f4fb; height: 100%;">-->
<!--					  <el-menu-->
<!--					    :default-active="tabIndex"-->
<!--					    class="tabMenu"-->
<!--					    style="margin-top: 50px;">-->
<!--					  	<el-menu-item :index="index+''" @click="setMajorgory(item.displayValue)" style="text-align: center;" v-for="(item, index) in subjectDropList">-->
<!--					  		{{item.displayName}}-->
<!--					  	</el-menu-item>-->
<!--					  </el-menu>-->
<!--				  </el-col>-->
				  <el-col :span="24" style="height: 100%;">
					  <el-table
							ref="dataListTable"
							  class="dataListTable"
							  :data="dataList"
							  header-align="center"
							  height="100%"
							  :row-class-name="tableRowClassName"
							  @selection-change="handleSelectionChange"
							  style="width: 100%;"
							  header-cell-class-name="stepPointTHeader">
						  <el-table-column
								  type="selection"
								  header-align="center"
								  align="center"
								  width="60">
						  </el-table-column>
						  <el-table-column
							  type="index"
							  header-align="center"
							  align="center"
							  width="60">
					  </el-table-column>
						  <el-table-column
								  prop="majorName"
								  label="专业"
								  width="120"
								  align="center">
						  </el-table-column>
<!--						  <el-table-column-->
<!--								  prop="categoryName"-->
<!--								  label="范畴"-->
<!--								  width="120"-->
<!--								  align="center">-->
<!--						  </el-table-column>-->
						  <el-table-column
								  prop="probContext"
								  label="检验项目"
								  width="200"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  prop="testReferences"
								  label="参考文件/标准"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  prop="testTypeName"
								  label="检验形式"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  fixed="right"
								  header-align="center"
								  align="center"
								  width="250"
								  v-if="showApply || showEdit || showDelete"
								  label="操作">
							  		 <template slot-scope="scope">
										<el-button v-preventReClick type="warning" size="small"  @click="applyHandle(scope.row.major,scope.row.itemId)" v-if="showApply">申请</el-button>
							  			<el-button v-preventReClick type="danger" size="small" @click="addOrUpdateHandle(scope.row.itemId)" v-if="showEdit">修改</el-button>
										<el-button v-preventReClick type="success" size="small" @click="deleteHandle(scope.row.itemId,scope.row.probContext)" v-if="showDelete">删除</el-button>
									 </template>
						  </el-table-column>
					  </el-table>
				  </el-col>
			  </el-row>
		  </el-card>
	  </div>
      <!-- 弹窗, 新增节点 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
	  <test-item-upload v-if="itemUploadVisible" ref="testItemUpload" @refreshDataList="getDataList()"></test-item-upload>
	  <information-apply v-if="applyVisible" ref="informationApply" @refreshDataList="getDataList()"></information-apply>
  </div>
</template>
<script>
import AddOrUpdate from './testItem-add-or-update'
import TestItemUpload from './testItem-upload'
import InformationApply from './information-apply'
import $common from "@/utils/common.js"
export default {
  data() {
    return {
    	showSelect: false,
		showSearch: false,
		showAdd: false,
		showDownload: false,
		showUpload: false,
		showDelete: false,
		showApply: false,
		showEdit:false,
		pageSpan:12,
		templateUrl: '',
		form:{},
		tabIndex: "0",
		value:"",
		//标的物
		subjectDropList: [],
		dataList: [],
		addOrUpdateVisible: false,
		itemUploadVisible : false,
		applyVisible: false,
		checked:"",
		selAllFlg: false,
		//当前页码
		pageIndex: 1,
		//当前记录数
		pageSize: 10,
		//总页数
		totalPage: 0,
		workNo: $common.getItem("workNo"),
		majorList:[],
		major: "",
		subjectNo: $common.getItem("subjectNo"),
		category: "",
		tableSelVal:[],
		tableSelIdArray:[]
    };
  },
  components: {
      AddOrUpdate,
	  TestItemUpload,
	  InformationApply
  },
	mounted() {
  		this.category = this.$route.query.type;
  		this.showSelect = $common.isAuth('testItem:select:'+this.category);
		this.showSearch = $common.isAuth('testItem:search:'+this.category);
		this.showAdd = $common.isAuth('testItem:add:'+this.category);
		this.showDownload = $common.isAuth('testItem:download:'+this.category);
		this.showUpload = $common.isAuth('testItem:upload:'+this.category);
		this.showDelete = $common.isAuth('testItem:delete:'+this.category);
		this.showApply = $common.isAuth('testItem:apply:'+this.category);
		this.showEdit = $common.isAuth('testItem:edit:'+this.category);
		if (!this.showSelect && !this.showAdd && !this.showDelete && !this.showDownload && !this.showUpload){
			this.pageSpan = 24
		}
		this.pageIndex = 1;
		this.major = "";
		if (this.showSearch){
			this.getDataList();
		}
		this.getMajorList();
        // this.getSubjectDropDown();
	},
  methods: {
	  downloadTemplate(){
		this.$http({
			url:  this.$store.state.httpUrl + "/common/getTemplateUrl?type=0",
			method: 'get',
			params: {}
		}).then(({data}) => {
			if (data && data.resultCode === 200) {
				if (data.body){
					window.open(data.body);
				} else {
					this.$message.error("未配置模板，请联系运维管理人员")
				}
			}
		});
	},
	  // 获取数据列表
	  getDataList() {
		  this.$http({
			  url:  this.$store.state.httpUrl + "/business/testitem/list",
			  method: 'get',
			  params: this.$http.adornParams({
				  page: this.pageIndex,
				  major:this.major,
				  subjectNo:this.subjectNo,
				  category: this.category,
				  limit: this.pageSize,
				  workNo: $common.getItem("workNo"),
			  })
		  }).then(({data}) => {
			  if (data && data.resultCode === 200) {
				  this.dataList = data.body.records;
				  this.totalPage = data.body.totalCount;
			  } else {
				  this.dataList = []
				  this.totalPage = 0
			  }
		  })
	  },
	  toggleAllSelection() {
	  	this.$refs.dataListTable.toggleAllSelection();
	  },
	  deletePHandle(){
	  	if (this.tableSelIdArray.length ===0){
			this.$message.error("请选择需要删除的报验项目数据");
		}else {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url:  this.$store.state.httpUrl + "/business/testitem/delete",
					method: 'post',
					data: this.tableSelIdArray
				}).then(({data}) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		}
	  },
	  //删除
	  deleteHandle (id,testItem) {
		  this.$confirm(`确定要删除选中的记录吗?`, '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
		  }).then(() => {
			  this.$http({
				  url:  this.$store.state.httpUrl + "/business/testitem/delete",
				  method: 'post',
				  data: [id]
			  }).then(({data}) => {
				  if (data && data.resultCode === 200) {
					  this.$message({
						  message: '操作成功',
						  type: 'success',
						  duration: 1500,
						  onClose: () => {
							  this.getDataList()
						  }
					  })
				  } else {
					  this.$message.error(data.msg)
				  }
			  })
		  })
	  },
	  //获取专业
	  getMajorList(){
		  this.$http({
			  url:  this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
			  method: "get",
			  params: {},
		  }).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				  this.majorList = data.body;
			  }
		  });
	  },
	  setMajorgory(value){
		  this.subjectNo = value;
		  this.pageIndex = 1;
		  this.major = "";
		  this.getDataList();
	  },

	  //获取标的物
	  getSubjectDropDown() {
		  this.$http({
			  url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
			  method: "get",
			  params: {},
		  }).then(({data}) => {
			  if (data && data.resultCode === 200) {
				  this.subjectDropList = data.body;
				  this.subjectNo = data.body[0].displayValue;
				  this.getDataList();
			  }
		  })
	  },

	  // 每页数
	  sizeChangeHandle(val) {
		  this.pageSize = val
		  this.pageIndex = 1
		  this.getDataList()
	  },
	  // 当前页
	  currentChangeHandle(val) {
		  this.pageIndex = val
		  this.getDataList()
	  },
	  applyHandle(major,testItem){
		  this.applyVisible = true
		  this.$nextTick(() => {
			  this.$refs.informationApply.init(major,testItem,this.category);
		  })
		  // this.$confirm(`确定对【检验项目=${testItem}】进行报验申请么?`, '提示', {
			//   confirmButtonText: '确定',
			//   cancelButtonText: '取消',
			//   type: 'warning'
		  // }).then(() => {
			//   this.$http({
			// 	  url:  this.$store.state.httpUrl +`/business/testitem/saveTestId`,
			// 	  method: "post",
			// 	  data: this.$http.adornData({
			// 		  itemId: id
			// 	  })
			//   }).then(({ data }) => {
			// 	  if (data && data.resultCode === 200) {
			// 		  this.$message({
			// 			  message: "申请成功",
			// 			  type: 'success',
			// 			  duration: 1500,
			// 		  });
			// 	  }
			//   });
		  // });

	  },
      // 新增检验项目
      addOrUpdateHandle (id){
          this.addOrUpdateVisible = true
          this.$nextTick(() => {
              this.$refs.addOrUpdate.init(id,this.category)
          })
      },
	  uploadTestItem () {
	  	this.itemUploadVisible = true;
		  this.$nextTick(() => {
			  this.$refs.testItemUpload.init()
		  })
	  },
	  siteContentViewHeight () {
		  var height = this.$store.state.common.documentClientHeight - 260;
		  return { height: height + 'px' }
	  },
	  handleSelectionChange(selection,item) {
	  	this.tableSelVal = selection;
	  	let _tableSelIdArray = [];
	  	selection.forEach((item) => {
	  		_tableSelIdArray.push(item.itemId)
	  	})
	  	this.tableSelIdArray = _tableSelIdArray;
	  	if(selection.length == this.dataList.length){
	  		this.selAllFlg = true;
	  	}else{
	  		this.selAllFlg = false;
	  	}
	  },
	  tableRowClassName({ row, rowIndex }) {
		  let color = ''
		  for(let item of this.tableSelVal){
			  if(item.itemId === row.itemId)color = 'table-SelectedRow-bgcolor'
		  }
		  return color
	  },
	resetFormData(){
		this.major = "";
	},
	selGetDataList(){
		this.pageIndex = 1;
		this.getDataList();
	},
  }
};
</script>
<style lang="scss" scoped="scoped">
	.selItemText{
		font-size: 14px;
		color: #1c1c1c;
		display: inline-block;
		width: 80px;
	}
</style>
